
const je = require('json-encrypt')
let user_permissions = window.localStorage.getItem("user_permissions");
user_permissions = JSON.parse(JSON.parse(je.decrypt(user_permissions)));

var modules = [{
  _name: 'CSidebarNavItem',
  name: 'Inicio',
  to: '/inicio/inicio',
  icon: 'cilHome',
}];

if(user_permissions.indexOf('CashList') > -1 || user_permissions.indexOf('ExpenseList') > -1 || user_permissions.indexOf('IncomeList') > -1 ){
  let me = this;
  let item = [];
  if (user_permissions.indexOf('CashList') > -1) {
    item.push({ name: 'Cajas', to: '/caja/listar' });
  }
  if (user_permissions.indexOf('CashAdd') > -1) {
    item.push({ name: 'Cierre de Caja', to: '/caja/nuevo' });
  }
  if (user_permissions.indexOf('IncomeList') > -1) {
    item.push({ name: 'Ingresos', to: '/ingresos/listar'});
  }
  if (user_permissions.indexOf('ExpenseList') > -1) {
    item.push({ name: 'Egresos', to: '/egresos/listar'});
  }
  

  let TypeUser = {
    _name: 'CSidebarNavDropdown',
    name: 'Caja',
    icon: 'cib-cashapp',
    items: item
  }
  modules.push(TypeUser);
}




if(user_permissions.indexOf('ClientList') > -1 ){
  let me = this;

  let TypeUser = {
    _name: 'CSidebarNavItem',
    name: 'Huéspedes ',
    to: '/cliente/listar',
    icon: 'cil-people',
  }
  modules.push(TypeUser);
}


if(user_permissions.indexOf('ProductList') > -1 || user_permissions.indexOf('CategoryList') > -1 ){
  let me = this;
  let item = [];
  if (user_permissions.indexOf('ProductList') > -1) {
    item.push({ name: 'Productos', to: '/producto/listar' });
    
  }
  if (user_permissions.indexOf('CategoryList') > -1) {
    item.push({ name: 'Categorias', to: '/categoria/listar'});
  }

  let TypeUser = {
    _name: 'CSidebarNavDropdown',
    name: 'Productos',
    icon: 'cil-applications',
    items: item
  }
  modules.push(TypeUser);
}




if(user_permissions.indexOf('ShoppingList') > -1 || user_permissions.indexOf('ProviderList') > -1 ){
  let me = this;
  let item = [];
  if (user_permissions.indexOf('ShoppingList') > -1) {
    item.push({ name: 'Compras', to: '/compra/listar' });
    
  }
  if (user_permissions.indexOf('ProviderList') > -1) {
    item.push({ name: 'Proveedores', to: '/proveedor/listar'});
  }

  let TypeUser = {
    _name: 'CSidebarNavDropdown',
    name: 'Compra',
    icon: 'cil-cart',
    items: item
  }
  modules.push(TypeUser);
}


// if(user_permissions.indexOf('RoomList') > -1 || user_permissions.indexOf('TypeRoomList') > -1 ){
//   let me = this;
//   let item = [];
//   if (user_permissions.indexOf('RoomList') > -1) {
//     item.push({ name: 'Habitaciones', to: '/habitacion/listar' });
    
//   }
//   if (user_permissions.indexOf('TypeRoomList') > -1) {
//     item.push({ name: 'Tipo de Habitaciones', to: '/tipo-habitacion/listar'});
//   }

//   let TypeUser = {
//     _name: 'CSidebarNavDropdown',
//     name: 'Habitaciones',
//     icon: 'cil-room',
//     items: item
//   }
//   modules.push(TypeUser);
// }


if(user_permissions.indexOf('RoomControlList') > -1 || user_permissions.indexOf('RoomList') > -1 || user_permissions.indexOf('TypeRoomList') > -1){
  let me = this;
  let item = [];
  if (user_permissions.indexOf('RoomControlList') > -1) {
    item.push({ name: 'Control Habitación', to: '/control-habitacion/listar' });
  }
  if (user_permissions.indexOf('RoomingListList') > -1) {
    item.push({ name: 'Rooming List', to: '/rooming-list/listar' });
  }
  if (user_permissions.indexOf('PaymentAccountList') > -1) {
    item.push({ name: 'Abonos', to: '/pago-a-cuenta/listar'});
  }
  if (user_permissions.indexOf('PaymentList') > -1) {
    item.push({ name: 'Comprobantes', to: '/pago/listar'});
  }

  if (user_permissions.indexOf('RoomList') > -1) {
    item.push({ name: 'Nro Habitaciones', to: '/habitacion/listar' });
    
  }
  if (user_permissions.indexOf('TypeRoomList') > -1) {
    item.push({ name: 'Tipo de Habitaciones', to: '/tipo-habitacion/listar'});
  }
 

  let TypeUser = {
    _name: 'CSidebarNavDropdown',
    name: 'Control Habitación',
    icon: 'cil-calendar-check',
    items: item
  }
  modules.push(TypeUser);
}

if(user_permissions.indexOf('MaintenanceList') > -1 ){
  let me = this;

  let TypeUser = {
    _name: 'CSidebarNavItem',
    name: 'Limpieza',
    to: '/mantenimiento/listar',
    icon: 'cilSync',
  }
  modules.push(TypeUser);
}


if(user_permissions.indexOf('ReservationList') > -1 ){
  let me = this;

  let TypeUser = {
    _name: 'CSidebarNavItem',
    name: 'Reserva',
    to: '/reserva/listar',
    icon: 'cilCalendar',
  }
  modules.push(TypeUser);
}



if(user_permissions.indexOf('ReportGuestList') > -1 || user_permissions.indexOf('ReportMinceturList') > -1  || user_permissions.indexOf('ReportRoomTypeList') > -1){
  let me = this;
  let item = [];
  if (user_permissions.indexOf('ReportGuestList') > -1) {
    item.push({ name: 'Huéspedes', to: '/reporte/huespedes' }); 
  }
  if (user_permissions.indexOf('ReportMinceturList') > -1) {
    item.push({ name: 'Mincetur', to: '/reporte/mincetur'});
  }
  if (user_permissions.indexOf('ReportPaymentList') > -1) {
    item.push({ name: 'Pagos',to: '/reporte/pagos'});
  }
  if (user_permissions.indexOf('ReportVoucherList') > -1) {
    item.push({ name: 'Comprobantes',to: '/reporte/comprobantes'});
  }
  
  let TypeUser = {
    _name: 'CSidebarNavDropdown',
    name: 'Reporte',
    icon: 'cil-applications',
    items: item
  }
  modules.push(TypeUser);
}



if(user_permissions.indexOf('UserList') > -1 || user_permissions.indexOf('UserTypeList') > -1  || user_permissions.indexOf('VoucherpeList') > -1 || user_permissions.indexOf('BusinessEdit') > -1){
  let me = this;
  let item = [];
  if (user_permissions.indexOf('UserList') > -1) {
    item.push({ name: 'Usuario', to: '/usuario/listar' });
    
  }
  if (user_permissions.indexOf('UserTypeList') > -1) {
    item.push({ name: 'Tipo de Usuario', to: '/tipo-usuario/listar'});
  }
  if (user_permissions.indexOf('VoucherList') > -1) {
    item.push({ name: 'Comprobante',to: '/comprobante/listar'});
  }
  if (user_permissions.indexOf('BusinessEdit') > -1) {
    item.push({name: 'Empresa',to: '/empresa/editar'});
  }
  let TypeUser = {
    _name: 'CSidebarNavDropdown',
    name: 'Mantenimiento',
    icon: 'cil-applications',
    items: item
  }
  modules.push(TypeUser);
}



export default [
  {
    _name: 'CSidebarNav',
    _children: modules
  }
]